<script setup lang="ts">
import type { MotorcycleDatabase } from '@/types/supabaseMotorcycle'

const user = useSupabaseUser()

const { t } = useLocale()
const { haversine } = useHaversine()

const { mainSearchExpertRadius, mainSearchLocationAddress, mainSearchLocationLatitude, mainSearchLocationLongitude } = storeToRefs(useUserDataStore())

const { fetchData, fetchColumnArrayData, schemaFetchOptionData, schemaFetchOptionExceptionData, schemaFetchRangeData } = useFetchComposable()

type ReviewOwner = MotorcycleDatabase['motorcycle']['Views']['viewReviews']['Row']
  & { profiles: Database['public']['Tables']['profiles']['Row'] & { avatar_url: string } }

type ExpertOwner = Database['public']['Views']['viewNeighborhoodOwnerProfiles']['Row']
  & { mainAvatarImage: Database['public']['Tables']['avatarList']['Row'] }
  & { mainLocation: Database['public']['Tables']['locationList']['Row'] }
  & { rank: number }

type ExpertRepair = Database['public']['Views']['viewNeighborhoodRepairProfiles']['Row']
  & { mainAvatarImage: Database['public']['Tables']['avatarList']['Row'] }
  & { mainLocation: Database['public']['Tables']['locationList']['Row'] }
  & { rank: number }

type ExpertCoaching = Database['public']['Views']['viewNeighborhoodCoachingProfiles']['Row']
  & { mainAvatarImage: Database['public']['Tables']['avatarList']['Row'] }
  & { mainLocation: Database['public']['Tables']['locationList']['Row'] }
  & { rank: number }

type ExpertPickup = Database['public']['Views']['viewNeighborhoodPickupProfiles']['Row']
  & { mainAvatarImage: Database['public']['Tables']['avatarList']['Row'] }
  & { mainLocation: Database['public']['Tables']['locationList']['Row'] }
  & { rank: number }

type ExpertRescue = Database['public']['Views']['viewNeighborhoodRescueProfiles']['Row']
  & { mainAvatarImage: Database['public']['Tables']['avatarList']['Row'] }
  & { mainLocation: Database['public']['Tables']['locationList']['Row'] }
  & { rank: number }

useHead({
  title: t('pageTitle.home'),
})

definePageMeta({
  middleware: [
    'main-auth',
    'clear-article-page',
    'clear-search-model',
  ],
})

const loginSlideoverTrigger = ref(false)
const changeSearchRadiusModalTrigger = ref(false)

const computedHotestPending = computed(() => {
  return pendingHotMotorcycleReviewList.value || pendingHotBoardArticleList.value || pendingHotMarketArticleList.value
})

const { data: podiumTop3Data, pending: pendingPodiumTop3Data } = await useAsyncData('podiumTop3Data', async () => {
  const { data: response } = await useFetch('/api/profile/podiumTop3', {
    headers: useRequestHeaders(['cookie']),
    immediate: true,
    transform: (payload: Database['public']['Tables']['profiles']['Row'][]) => {
      return payload.slice(0, 3).map((item, index) => ({
        ...item,
        rank: index + 1,
      }))
    },
  })

  return response.value
    ? response.value as (Database['public']['Tables']['profiles']['Row'] & { rank: number })[]
    : []
})

const transformExpertData = async (locationResponse: Database['public']['Tables']['locationList']['Row'][], viewTableName: string) => {
  type NeighborhoodList =
    Database['public']['Views']['viewNeighborhoodOwnerProfiles']['Row'] |
    Database['public']['Views']['viewNeighborhoodRepairProfiles']['Row'] |
    Database['public']['Views']['viewNeighborhoodCoachingProfiles']['Row'] |
    Database['public']['Views']['viewNeighborhoodPickupProfiles']['Row'] |
    Database['public']['Views']['viewNeighborhoodRescueProfiles']['Row']

  const locationsWithRadius = locationResponse?.filter((location: Database['public']['Tables']['locationList']['Row']) => {
    return haversine(
      mainSearchLocationLatitude.value,
      mainSearchLocationLongitude.value,
      location?.latitude ?? 0,
      location?.longitude ?? 0,
    ) <= mainSearchExpertRadius.value
  })

  const uniqueLocations = locationsWithRadius?.filter((location, index, self) =>
    index === self.findIndex((loc: Database['public']['Tables']['locationList']['Row']) => (
      loc.latitude === location.latitude && loc.longitude === location.longitude
    )),
  )

  const currentLocationProfileIds = uniqueLocations
    ? uniqueLocations.map((location: Database['public']['Tables']['locationList']['Row']) => location.update_user_id).filter(Boolean)
    : []

  const neighborhoodList = await fetchColumnArrayData(viewTableName, '*', 'id', currentLocationProfileIds as string[], 'meeting_count', false) as NeighborhoodList[] | null

  return neighborhoodList?.map((list: NeighborhoodList, index: number) => {
    return {
      ...list,
      rank: index + 1,
    }
  }).slice(0, 5) as ExpertOwner[] | ExpertRepair[] | ExpertCoaching[] | ExpertPickup[] | ExpertRescue[]
}

const { data: mainLargeBannerList } = useAsyncData('mainLargeBannerList', async () => {
  const { data: response } = await useFetch('/api/ads/main/largeBanner', {
    headers: useRequestHeaders(['cookie']),
    immediate: true,
  })

  return response.value
    ? response.value
    : []
})

const { data: expertOwnerList } = useLazyAsyncData('meetingOwnerExpertList', async () => {
  let response = null

  user.value?.id
    ? response = await schemaFetchOptionExceptionData('motorcycle', 'viewReviews', '*', 'update_user_id', user.value?.id ?? '', 'enable_review_meeting', true, '', '')
    : response = await schemaFetchOptionData('motorcycle', 'viewReviews', '*', 'enable_review_meeting', true, '', '')

  const profileIds = response
    ? response.map((item: ReviewOwner) => item.profiles?.id).filter(Boolean)
    : []

  const profileResponse = await fetchColumnArrayData('locationList', '*', 'update_user_id', profileIds) as Database['public']['Tables']['locationList']['Row'][]

  return transformExpertData(profileResponse, 'viewNeighborhoodOwnerProfiles')
}, {
  watch: [mainSearchExpertRadius, mainSearchLocationLatitude, mainSearchLocationLongitude],
})

const { data: expertRepairList } = useLazyAsyncData('meetingRepairExpertList', async () => {
  let response = null

  user.value?.id
    ? response = await schemaFetchOptionExceptionData('public', 'viewProfiles', '*', 'id', user.value?.id ?? '', '', '', '', '')
    : response = await fetchData('viewProfiles', '*', 'detault')

  const profileResponse: Database['public']['Tables']['locationList']['Row'][] = []

  response.map((item: Database['public']['Views']['viewProfiles']['Row'] & { locationList: Database['public']['Tables']['locationList']['Row'][] }) => {
    if (!item.locationList) {
      return
    }

    item.locationList.map((location: Database['public']['Tables']['locationList']['Row']) => {
      profileResponse.push(location)
    })
  })

  return transformExpertData(profileResponse, 'viewNeighborhoodRepairProfiles')
}, {
  watch: [mainSearchExpertRadius, mainSearchLocationLatitude, mainSearchLocationLongitude],
})

const { data: expertCoachingList } = useLazyAsyncData('meetingCoachingExpertList', async () => {
  let response = null

  user.value?.id
    ? response = await schemaFetchOptionExceptionData('public', 'viewProfiles', '*', 'id', user.value?.id ?? '', '', '', '', '')
    : response = await fetchData('viewProfiles', '*', 'default')

  const profileResponse: Database['public']['Tables']['locationList']['Row'][] = []

  response.map((item: Database['public']['Views']['viewProfiles']['Row'] & { locationList: Database['public']['Tables']['locationList']['Row'][] }) => {
    if (!item.locationList) {
      return
    }

    item.locationList.map((location: Database['public']['Tables']['locationList']['Row']) => {
      profileResponse.push(location)
    })
  })

  return transformExpertData(profileResponse, 'viewNeighborhoodCoachingProfiles')
}, {
  watch: [mainSearchExpertRadius, mainSearchLocationLatitude, mainSearchLocationLongitude],
})

const { data: expertPickupList } = useLazyAsyncData('meetingPickupExpertList', async () => {
  let response = null

  user.value?.id
    ? response = await schemaFetchOptionExceptionData('public', 'viewProfiles', '*', 'id', user.value?.id ?? '', '', '', '', '')
    : response = await fetchData('viewProfiles', '*', 'default')

  const profileResponse: Database['public']['Tables']['locationList']['Row'][] = []

  response.map((item: Database['public']['Views']['viewProfiles']['Row'] & { locationList: Database['public']['Tables']['locationList']['Row'][] }) => {
    if (!item.locationList) {
      return
    }

    item.locationList.map((location: Database['public']['Tables']['locationList']['Row']) => {
      profileResponse.push(location)
    })
  })

  return transformExpertData(profileResponse, 'viewNeighborhoodPickupProfiles')
}, {
  watch: [mainSearchExpertRadius, mainSearchLocationLatitude, mainSearchLocationLongitude],
})

const { data: expertRescueList } = useLazyAsyncData('meetingRescueExpertList', async () => {
  let response = null

  user.value?.id
    ? response = await schemaFetchOptionExceptionData('public', 'viewProfiles', '*', 'id', user.value?.id ?? '', '', '', '', '')
    : response = await fetchData('viewProfiles', '*', 'default')

  const profileResponse: Database['public']['Tables']['locationList']['Row'][] = []

  response.map((item: Database['public']['Views']['viewProfiles']['Row'] & { locationList: Database['public']['Tables']['locationList']['Row'][] }) => {
    if (!item.locationList) {
      return
    }

    item.locationList.map((location: Database['public']['Tables']['locationList']['Row']) => {
      profileResponse.push(location)
    })
  })

  return transformExpertData(profileResponse, 'viewNeighborhoodRescueProfiles')
}, {
  watch: [mainSearchExpertRadius, mainSearchLocationLatitude, mainSearchLocationLongitude],
})

const { data: hotMotorcycleReviewList, pending: pendingHotMotorcycleReviewList } = useLazyAsyncData('hotMotorcycleReviewList', async () => {
  const response = await schemaFetchRangeData('motorcycle', 'viewReviews', '*', 0, 2, 'summary_spark', false)

  return response
    ? response
    : []
})

const { data: hotBoardArticleList, pending: pendingHotBoardArticleList } = useLazyAsyncData('hotBoardArticleList', async () => {
  const response = await schemaFetchRangeData('public', 'viewHotestBoardArticle', '*', 0, 2, 'hotest_point', false)

  return response
    ? response
    : []
})

const { data: hotMarketArticleList, pending: pendingHotMarketArticleList } = useLazyAsyncData('hotMarketArticleList', async () => {
  const response = await schemaFetchRangeData('public', 'viewHotestMarketArticle', '*', 0, 2, 'hotest_point', false)

  return response
    ? response
    : []
})

const selectSearchRadius = (radius: number) => {
  mainSearchExpertRadius.value = radius
  changeSearchRadiusModalTrigger.value = false
}

const moveToHotestReview = (reviewId: string, modelId: string, detailPath: string) => {
  !user.value?.id
    ? loginSlideoverTrigger.value = true
    : navigateTo({ path: detailPath, query: { modelId, reviewId } })
}

const moveToHotestArticle = (id: string, detailPath: string) => {
  !user.value?.id
    ? loginSlideoverTrigger.value = true
    : navigateTo(`${detailPath}/${id}`)
}

const moveToProfile = (id: string) => {
  !user.value?.id
    ? loginSlideoverTrigger.value = true
    : navigateTo(`/profile/${id}`)
}

const moveToSearchMeeting = (searchMeetingUrl: string) => {
  !user.value?.id
    ? loginSlideoverTrigger.value = true
    : navigateTo(searchMeetingUrl)
}

const moveToWritePost = (path: string) => {
  !user.value?.id
    ? loginSlideoverTrigger.value = true
    : navigateTo(path)
}

const moveToMainBanner = (adsTypeCode: string, linkUrl: string, articleId: string, articleCategory: string, motorcycleReviewId: string, motorcycleModelId: string) => {
  switch (adsTypeCode) {
    case 'ATC001':
    case 'ATC002':
      navigateTo(linkUrl, { external: true })
      break
    case 'ATC003':
      !user.value?.id
        ? loginSlideoverTrigger.value = true
        : navigateTo(`/announce/${articleCategory}/${articleId}`)
      break
    case 'ATC004':
      !user.value?.id
        ? loginSlideoverTrigger.value = true
        : navigateTo(`/board/${articleCategory}/${articleId}`)
      break
    case 'ATC005':
      !user.value?.id
        ? loginSlideoverTrigger.value = true
        : navigateTo(`/market/${articleCategory}/${articleId}`)
      break
    case 'ATC006':
      !user.value?.id
        ? loginSlideoverTrigger.value = true
        : navigateTo({ path: '/review/motorcycle/detail', query: { reviewId: motorcycleReviewId, modelId: motorcycleModelId } })
      break
    case 'ATC007':
      break
    case 'ATC008':
      !user.value?.id
        ? loginSlideoverTrigger.value = true
        : navigateTo(linkUrl)
      break
  }
}
</script>

<template>
  <div class="w-dvw tablet:w-[500px] flex flex-col items-center pt-5 pb-14">
    <FlickingMainPodiumTop3
      :vertical-data="podiumTop3Data"
      :is-pending="pendingPodiumTop3Data"
      :is-sign-in="!!user?.id"
      @click:podium-user="moveToProfile"
    />
    <FlickingMainLargeBanner
      :main-large-banner-list="mainLargeBannerList"
      :is-pending="computedHotestPending"
      :is-sign-in="!!user?.id"
      @click:main-banner="moveToMainBanner"
    />
    <MainHotMenu v-if="user?.id" />
    <WMDivider
      v-if="!user?.id"
      size="xl"
      :ui="{
        border: {
          base: 'border-white dark:border-gray01Dark',
          size: {
            horizontal: {
              xl: 'border-t-[20px]',
            },
          },
        },
      }"
    />
    <MainNeighborhoodExpert
      :is-sign-in="!!user?.id"
      :main-simple-address="mainSearchLocationAddress"
      :search-radius="mainSearchExpertRadius"
      :expert-owner-list="expertOwnerList"
      :expert-repair-list="expertRepairList"
      :expert-coaching-list="expertCoachingList"
      :expert-pickup-list="expertPickupList"
      :expert-rescue-list="expertRescueList"
      @click:expert-profile="moveToProfile"
      @click:search-meeting="moveToSearchMeeting"
      @click:change-search-radius="changeSearchRadiusModalTrigger = true"
    />
    <WMDivider
      size="xl"
      :ui="{
        border: {
          base: 'border-gray02Light dark:border-gray02Dark',
          size: {
            horizontal: {
              xl: 'border-t-[20px]',
            },
          },
        },
      }"
    />
    <MainHotestList
      :is-sign-in="!!user?.id"
      :is-review-pending="pendingHotMotorcycleReviewList"
      :is-board-pending="pendingHotBoardArticleList"
      :is-market-pending="pendingHotMarketArticleList"
      :hot-motorcycle-review-list="hotMotorcycleReviewList"
      :hot-board-article-list="hotBoardArticleList"
      :hot-market-article-list="hotMarketArticleList"
      @click:hotest-review="moveToHotestReview"
      @click:hotest-article="moveToHotestArticle"
      @click:search-meeting="moveToSearchMeeting"
      @click:write-post="moveToWritePost"
    />
    <AFooter />
    <WMSlideover
      v-model="loginSlideoverTrigger"
      class="login-gradient"
      side="bottom"
      :ui="{
        base: 'justify-end items-center',
        height: 'h-fit',
        padding: 'px-5 py-10',
      }"
    >
      <LoginButton
        v-if="!user?.id"
        class="max-w-[400px]"
      />
    </WMSlideover>
    <DialogChangeRadius
      v-model:change-radius-modal-trigger="changeSearchRadiusModalTrigger"
      @select:radius="selectSearchRadius"
    />
  </div>
</template>
