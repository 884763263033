<script lang="ts" setup>
const { t } = useLocale()
const { url } = useImageStorage()
const { comma } = useUi()
const colorMode = useColorMode()

type ExpertOwner = Database['public']['Views']['viewNeighborhoodOwnerProfiles']['Row']
  & { mainAvatarImage: Database['public']['Tables']['avatarList']['Row'] }
  & { mainLocation: Database['public']['Tables']['locationList']['Row'] }
  & { rank: number }

type ExpertRepair = Database['public']['Views']['viewNeighborhoodRepairProfiles']['Row']
  & { mainAvatarImage: Database['public']['Tables']['avatarList']['Row'] }
  & { mainLocation: Database['public']['Tables']['locationList']['Row'] }
  & { rank: number }

type ExpertCoaching = Database['public']['Views']['viewNeighborhoodCoachingProfiles']['Row']
  & { mainAvatarImage: Database['public']['Tables']['avatarList']['Row'] }
  & { mainLocation: Database['public']['Tables']['locationList']['Row'] }
  & { rank: number }

type ExpertPickup = Database['public']['Views']['viewNeighborhoodPickupProfiles']['Row']
  & { mainAvatarImage: Database['public']['Tables']['avatarList']['Row'] }
  & { mainLocation: Database['public']['Tables']['locationList']['Row'] }
  & { rank: number }

type ExpertRescue = Database['public']['Views']['viewNeighborhoodRescueProfiles']['Row']
  & { mainAvatarImage: Database['public']['Tables']['avatarList']['Row'] }
  & { mainLocation: Database['public']['Tables']['locationList']['Row'] }
  & { rank: number }

const props = withDefaults(
  defineProps<{
    isSignIn?: boolean
    mainSimpleAddress?: string
    searchRadius?: number
    expertOwnerList?: ExpertOwner[] | null
    expertRepairList?: ExpertRepair[] | null
    expertCoachingList?: ExpertCoaching[] | null
    expertPickupList?: ExpertPickup[] | null
    expertRescueList?: ExpertRescue[] | null
  }>(),
  {
    isSignIn: false,
    mainSimpleAddress: '',
    searchRadius: 0,
    expertOwnerList: () => [],
    expertRepairList: () => [],
    expertCoachingList: () => [],
    expertPickupList: () => [],
    expertRescueList: () => [],
  },
)

defineEmits([
  'click:expert-profile',
  'click:search-meeting',
  'click:change-search-radius',
])

const selectMeetingTabIndex = ref(0)

const meetingCategory = ref([
  { label: t('main.neighborhoodMeetingCategory.owner') },
  { label: t('main.neighborhoodMeetingCategory.repair') },
  { label: t('main.neighborhoodMeetingCategory.coaching') },
  { label: t('main.neighborhoodMeetingCategory.pickup') },
  { label: t('main.neighborhoodMeetingCategory.rescue') },
])

const switchMeetingData = () => {
  switch (selectMeetingTabIndex.value) {
    case 0:
      return props.expertOwnerList ?? []
    case 1:
      return props.expertRepairList ?? []
    case 2:
      return props.expertCoachingList ?? []
    case 3:
      return props.expertPickupList ?? []
    case 4:
      return props.expertRescueList ?? []
    default:
      return []
  }
}

const switchMeetingSearchButton = () => {
  switch (selectMeetingTabIndex.value) {
    case 0:
      return { label: t('myMeeting.searchMeeting', { meetingType: t('main.neighborhoodMeetingCategory.owner') }), path: '/meeting/owner' }
    case 1:
      return { label: t('myMeeting.searchMeeting', { meetingType: t('main.neighborhoodMeetingCategory.repair') }), path: '/meeting/repair' }
    case 2:
      return { label: t('myMeeting.searchMeeting', { meetingType: t('main.neighborhoodMeetingCategory.coaching') }), path: '/meeting/coaching' }
    case 3:
      return { label: t('myMeeting.searchMeeting', { meetingType: t('main.neighborhoodMeetingCategory.pickup') }), path: '/meeting/pickup' }
    case 4:
      return { label: t('myMeeting.searchMeeting', { meetingType: t('main.neighborhoodMeetingCategory.rescue') }), path: '/meeting/rescue' }
    default:
      return { label: '', path: '' }
  }
}
</script>

<template>
  <div class="w-full flex flex-col gap-y-2.5 px-5 my-5">
    <div class="w-full flex flex-col gap-y-1.5">
      <p class="text-xl font-semibold text-gray07Light dark:text-gray07Dark break-keep">
        {{ $t('main.neighborhoodExpertTitle') }}
      </p>
      <p class="text-sm font-light text-gray05Light dark:text-gray05Dark break-keep">
        {{ $t('main.locationTarget', { simpleAddress: mainSimpleAddress, distance: comma(searchRadius / 1000) }) }}
      </p>
      <WMTabs
        v-model="selectMeetingTabIndex"
        :items="meetingCategory"
        size="xl"
        :ui="{
          wrapper: 'space-y-0 w-full overflow-x-scroll border-b-2 border-gray02Light dark:border-gray02Dark',
          list: {
            base: 'flex-forced gap-x-2.5',
            width: 'w-fit',
            height: 'h-fit',
            padding: '',
            background: 'bg-transparent dark:bg-transparent',
            tab: {
              base: 'text-lg w-fit flex',
              size: 'text-lg',
              height: '',
              width: 'w-fit',
              active: 'leading-6 font-semibold text-gray07Light dark:text-gray07Dark bg-gray01Light dark:bg-gray01Dark border-b-2 rounded-none border-gray07Light dark:border-gray07Dark',
              inactive: 'leading-6 text-gray04Light dark:text-gray04Dark bg-transparent border-b-2 border-transparent rounded-none',
              padding: 'py-4 px-3',
            },
          },
        }"
      />
    </div>
    <div class="w-full flex flex-col gap-y-2.5">
      <div
        v-if="switchMeetingData().length === 0"
        class="flex flex-col gap-y-4 items-center py-36 px-8"
      >
        <NuxtImg
          :src="url(true, colorMode.value === 'light' ? '/assets/icon/spanner.svg' : '/assets/icon/spanner_dark.svg')"
          class="w-[60px] h-[60px]"
          :draggable="false"
          @contextmenu.prevent
        />
        <div class="flex flex-col items-center">
          <p
            v-for="(text, index) in $tm('main.neighborhoodMeetingCategory.noExpert')"
            :key="index"
            class="text-md text-center font-semibold text-gray04Light dark:text-gray04Dark break-keep"
          >
            {{ $rt(text) }}
          </p>
        </div>
        <AButton
          custom-class="text-sm font-semibold"
          button-variant="soft"
          button-size="lg"
          button-color="black"
          button-confirm-color="green"
          :button-text="$t('button.changeSearchRadius')"
          @click="() => $emit('click:change-search-radius')"
        />
      </div>
      <div
        v-for="(item, index) in switchMeetingData()"
        v-show="switchMeetingData().length > 0"
        :key="index"
        class="w-full flex flex-wrap items-center gap-x-3 gap-y-1 py-5"
      >
        <span class="text-base font-semibold text-gray07Light dark:text-gray07Dark">
          {{ item.rank }}
        </span>
        <AUserAvatar
          :avatar-url="item.mainAvatarImage.url ?? ''"
          :podium-point="item.podium_point ?? 0"
          avatar-size-class="w-12 h-12"
          :is-large-chip="false"
        />
        <div class="flex flex-col">
          <div class="flex flex-wrap items-center gap-x-1">
            <span class="text-base font-semibold text-gray05Light dark:text-gray05Dark">
              {{ item.nickname }}
            </span>
            <WMBadge
              v-if="selectMeetingTabIndex === 1"
              variant="soft"
              size="sm"
              :label="item.is_business ? $t('myMeeting.shop') : $t('myMeeting.individual')"
              :ui="{
                variant: {
                  soft: 'font-semibold text-gray05Light dark:text-gray05Dark bg-gray02Light dark:bg-gray02Dark',
                },
              }"
            />
          </div>
          <span class="text-sm font-light text-gray05Light dark:text-gray05Dark">
            {{ $t('main.neighborhoodMeetingCategory.receiveMeetingCount', { count: item.meeting_count }) }}
          </span>
        </div>
        <div class="flex-auto" />
        <AButton
          button-variant="soft"
          button-color="black"
          :button-confirm-color="isSignIn ? 'gray' : 'white'"
          :use-leading="!isSignIn"
          :image-url="url(true, colorMode.value === 'light' ? '/assets/icon/lock_button.svg' : '/assets/icon/lock_button_dark.svg')"
          image-class="w-4 h-4"
          :button-text="isSignIn ? $t('button.viewProfile') : $t('button.profile')"
          @click="() => $emit('click:expert-profile', item.id)"
        />
      </div>
      <AButton
        :custom-class="'w-full gap-x-0'"
        label-class="py-0.5"
        button-block
        button-variant="outline"
        button-size="xl"
        :use-leading="!isSignIn"
        use-trailing
        :image-url="url(true, colorMode.value === 'light' ? '/assets/icon/lock_button.svg' : '/assets/icon/lock_button_dark.svg')"
        image-class="w-4 h-4 mr-1"
        :image-trail-url="url(true, '/assets/icon/arrow_right_middle.svg')"
        image-trail-class="w-6 h-6"
        :button-text="switchMeetingSearchButton().label"
        @click="() => $emit('click:search-meeting', switchMeetingSearchButton().path)"
      />
    </div>
  </div>
</template>
