<script lang="ts" setup>
import type { FilterDatabase } from '@/types/supabaseFilter'
import type { MotorcycleDatabase } from '@/types/supabaseMotorcycle'

const { url } = useImageStorage()

type HotMotorcycleReview = MotorcycleDatabase['motorcycle']['Views']['viewReviews']['Row']
  & { viewsList: MotorcycleDatabase['motorcycle']['Tables']['reviewCount']['Row'][] }
  & { profiles: Database['public']['Tables']['profiles']['Row']
    & { avatar_url: string }
  }
  & { vehicleModel: MotorcycleDatabase['motorcycle']['Tables']['vehicleModel']['Row'] }
  & { manufacturers: FilterDatabase['filter']['Tables']['manufacturers']['Row'] }
  & { motoGenre: FilterDatabase['filter']['Tables']['motoGenre']['Row'] }
  & { reviewImageList: MotorcycleDatabase['motorcycle']['Tables']['reviewImageList']['Row'][] }

withDefaults(
  defineProps<{
    hotestReview?: HotMotorcycleReview | null
  }>(),
  {
    hotestReview: null,
  },
)
</script>

<template>
  <div class="w-full flex gap-x-4">
    <div class="w-[calc(100%-4.5rem)] flex flex-col gap-y-1">
      <p class="text-lg font-semibold text-black dark:text-white truncate">
        {{ hotestReview?.vehicleModel.model + ' ' + hotestReview?.vehicleModel.release }}
      </p>
      <ClientOnly>
        <div class="text-base font-light text-gray05Light dark:text-gray05Dark truncate">
          {{ hotestReview?.driving_performance_text }}
        </div>
      </ClientOnly>
    </div>
    <NuxtImg
      v-if="hotestReview?.review_image_url"
      class="w-14 h-14 object-cover rounded-md ring-1 ring-gray03Light dark:ring-gray03Dark"
      :src="hotestReview?.review_image_url ?? ''"
    />
  </div>
  <div class="flex flex-wrap items-center justify-between">
    <div class="flex items-center gap-x-1">
      <ACrown
        v-if="hotestReview?.profiles.podium_point"
        :podium-point="hotestReview?.profiles.podium_point"
        :badge-class="'w-10 py-0.5 rounded-full border-2 border-white bg-black'"
      />
      <div
        v-else
        class="w-4 h-4 flex items-center justify-center rounded-full bg-wnmPrimary"
      >
        <NuxtImg
          :src="hotestReview?.profiles?.avatar_url ?? url(true, '/assets/logo/wnm_logo_white.svg')"
          :class="hotestReview?.profiles?.avatar_url ? 'w-4 h-4 object-cover rounded-full' : 'w-3 h-3'"
        />
      </div>
      <span class="text-sm font-light text-gray05Light dark:text-gray05Dark">
        {{ hotestReview?.profiles.nickname }}
      </span>
      <span class="text-xs font-semibold text-gray04Light dark:text-gray04Dark">
        {{ $t('text.centerDot') }}
      </span>
      <NuxtImg
        :src="url(true, '/assets/icon/bolt_line.svg')"
        class="w-4 h-4"
      />
      <span class="text-sm font-light text-gray05Light dark:text-gray05Dark">
        {{ hotestReview?.summary_spark }}
      </span>
      <span class="text-xs font-semibold text-gray04Light dark:text-gray04Dark">
        {{ $t('text.centerDot') }}
      </span>
      <NuxtImg
        :src="url(true, '/assets/icon/eye_line.svg')"
        class="w-4 h-4"
      />
      <span class="text-sm font-light text-gray05Light dark:text-gray05Dark">
        {{ hotestReview?.viewsCount }}
      </span>
    </div>
    <ANuxtTime
      class="text-sm font-light text-gray05Light dark:text-gray05Dark"
      :date-time="hotestReview?.created_at"
      :capital-date-time="false"
      :full-date-time="false"
    />
  </div>
</template>
