<script lang="ts" setup>
const { t } = useLocale()
const { url } = useImageStorage()

const hotMenuList = ref([
  {
    label: t('main.hotMenu.motorcycleReview'),
    url: '/review/motorcycle/list',
    imageUrl: 'motorcycle.svg',
  },
  {
    label: t('main.hotMenu.board'),
    url: '/board/free',
    imageUrl: 'board.svg',
  },
  {
    label: t('main.hotMenu.market'),
    url: '/market/over125Manual',
    imageUrl: 'market.svg',
  },
  {
    label: t('main.hotMenu.meetingManage'),
    url: '/my/meeting',
    imageUrl: 'meeting_manage.svg',
  },
])
</script>

<template>
  <div class="flex flex-wrap items-center gap-3 my-5 mx-10">
    <div
      v-for="(item, index) in hotMenuList"
      :key="index"
      class="flex flex-col items-center gap-y-1.5 px-2 cursor-pointer"
      @click="navigateTo(item.url)"
    >
      <NuxtImg
        :src="url(true, `/assets/menu/${item.imageUrl}`)"
        class="w-[60px] h-[60px]"
      />
      <p class="text-sm font-semibold text-gray05Light dark:text-gray05Dark">
        {{ item.label }}
      </p>
    </div>
  </div>
</template>
